import React from 'react'

const Laws = () => {
  return (
    <>
     <section class="page-title" style={{backgroundImage:'url(assets/images/background/pagetitle-bg.jpg)'}}>
            <div class="auto-container">
            	<h1>QANUNVERİCİLİK</h1>
            </div>
        </section>
        <section class="page-info">
            <div class="auto-container clearfix">
            	<div class="pull-left"><h2>Qanunvericilik</h2></div>
                <div class="pull-right">
                	<ul class="bread-crumb clearfix">
                    	<li><a href="/">Əsas səhifə</a></li>
                        <li>Qanunvericilik</li>
                    </ul>
                </div>
            </div>
        </section>
        </>
  )
}

export default Laws