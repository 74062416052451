import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function ListUser() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    name: "",
    category: ""
  });

  const { id } = useParams();

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get(`https://www.posstore.az/posstore/dashboard.php/${id}`)
      .then(function (response) {
        setInputs(response.data);
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .put(`https://www.posstore.az/posstore/dashboard.php/${id}/edit`, inputs)
      .then(function (response) {
        navigate("/dashboard");
      });
  };

  return (
    <div>
      <h1>Edit user</h1>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th>
                <label>Name: </label>
              </th>
              <td>
                <input
                  value={inputs.name}
                  type="text"
                  name="name"
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>category: </label>
              </th>
              <td>
                <input
                  value={inputs.category}
                  type="text"
                  name="category"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* <tr>
              <th>
                <label>image: </label>
              </th>
              <td>
                <input
                  value={inputs.image}
                  type="text"
                  name="image"
                  onChange={handleChange}
                />
              </td>
            </tr> */}
            <tr>
              <td colSpan="2" align="right">
                <button>Save</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}
