// features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    member: '', // Add a field for user's first name
    surname: '', // Add a field for user's last name
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.member = action.payload.firstName; // Set the user's first name
      state.surname = action.payload.lastName; // Set the user's last name
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.member = ''; // Clear the user's first name on logout
      state.surname = ''; // Clear the user's last name on logout
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;



