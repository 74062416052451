import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
     <footer class="main-footer">
            
            {/* <!--Footer Upper-->         */}
            <div class="footer-upper" style={{backgroundImage:"url(assets/images/background/footer-bg.jpg)"}}>
                <div class="auto-container">
                    <div class="row clearfix">
                        
                        {/* <!--Two 4th column--> */}
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <div class="row clearfix">
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                    <div class="footer-widget about-widget">
                                        <h3>Haqqımızda</h3>
                                        <div class="text">
                                            <p>SOCAR-ın Karbamid zavodunun istehsala başlaması azot gübrəsi idxalına ehtiyacı aradan qaldıracaq, bununla da ölkənin xarici valyuta xərcləri azalacaqdır.</p>
                                            <p>Zavod il ərzində 650-660 min ton istehsal gücünə malikdir. </p>
                                        </div>
                                        
                                        <a href="/register" class="theme-btn btn-style-one">Üzvlük</a>
                                        
                                    </div>
                                </div>
                                
                                {/* <!--Footer Column--> */}
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                    <h3>Sürətli keçidlər</h3>
                                    <div class="row clearfix">
                                        
                                            <div style={{marginLeft:'17px'}} class="footer-widget links-widget">
                                                <ul>
                                                    <li><a href="/about">Haqqımızda</a></li>
                                                    <li><a href="/partners">Tərəfdaşlarımız</a></li>
                                                    <li><a href="/faq">FAQ</a></li>
                                                    <li><a href="/events">Tədbirlər</a></li>
                                                    <li><a href="/news">Xəbərlər</a></li>
                                                    <li><a href="/statistics">Statistika</a></li>
                                                    <li><a href="/laws">Qanunvericilik</a></li>
                                                    <li><a href="/documents">Sənəd nümunələri</a></li>
                                                </ul>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* < */}
                          {/* !--Two 4th column End--> */}
                        
                        {/* <!--Two 4th column--> */}
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <div class="row clearfix">
                                {/* <!--Footer Column--> */}
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                    <div class="footer-widget twitter-feeds">
                                        <h3>Ən son tədbirlər</h3>	
                                        <div class="feed">
                                            <div class="icon"><span class="fa fa-twitter"></span></div>
                                            <div class="feed-content"><a href="#">@SteelThemes</a>  Lawyer Justice  Best Template Now.</div>
                                            <div class="time">10 Mins Ago</div>
                                        </div>
                                        
                                        <div class="feed">
                                            <div class="icon"><span class="fa fa-twitter"></span></div>
                                            <div class="feed-content"><a href="#">@SteelThemes</a>  They Provide  Service in the World.</div>
                                            <div class="time">2 Hours Ago</div>
                                        </div>
                                        
                                        <div class="feed">
                                            <div class="icon"><span class="fa fa-twitter"></span></div>
                                            <div class="feed-content"><a href="#">@Dragon Tech</a>  Lawyer Justice Have experinced Attorneys.</div>
                                            <div class="time">2 Hours Ago</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                {/* <!--Footer Column--> */}
                                <div class="col-lg-6 col-sm-6 col-xs-12 column">
                                    <div class="footer-widget newsletter-widget">
                                        <h3>Əlaqə</h3>
                                        
                                        <div class="newsletter-form">
                                            <p>Qeydiyyatdan keçin və son yeniliklərdən xəbərdar olun</p>
                                            <form method="post" action="index.html">
                                                <div class="form-group">
                                                    <input type="email" name="useremail" value="" placeholder="Email address" required=""></input>
                                                    <button type="submit" class="theme-btn btn-submit">Go</button>
                                                </div>
                                                <p><em>Konfidensiallıq qorunur</em></p>
                                            </form>
                                        </div>
{/*                                         
                                        <h4>Sosial Media</h4>
                                        
                                        <div class="social-links">
                                            <a href="#"><span class="fa fa-facebook-f"></span></a>
                                            <a href="#"><span class="fa fa-twitter"></span></a>
                                            <a href="#"><span class="fa fa-google-plus"></span></a>
                                            <a href="#"><span class="fa fa-linkedin"></span></a>
                                            <a href="#"><span class="fa fa-skype"></span></a>
                                        </div> */}
                                    </div>
                                </div>
                            </div> 
                        </div>
                        {/* <!--Two 4th column End--> */}
                        
                    </div>
                    
                </div>
            </div>
            
            {/* <!--Footer Bottom--> */}
            <div class="footer-bottom">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-md-6 col-sm-6 col-xs-12"><div class="copyright-text">Copyrights © 2024 Bütün hüquqlar qorunur</div></div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            {/* <!--Bottom Nav--> */}
                            {/* <nav class="footer-nav clearfix">
                                <ul class="pull-right clearfix">
                                    <li><a href="#">Terms Of Use </a></li>
                                    <li><a href="#">Privacy &amp; Security Statement</a></li>
                                </ul>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
            
        </footer>
    </>
  );
}
