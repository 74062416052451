import React from 'react'
import Workers from '../../Components/Workers/Workers'
import Leaders from '../../Components/Leaders/Leaders'
import Members from '../../Components/Members/Members'

const Team = () => {
  return (
   <>
    <section class="page-title" style={{backgroundImage:'url(assets/images/background/pagetitle-bg.jpg)'}}>
            <div class="auto-container">
            	<h1>Komandamız</h1>
            </div>
        </section>
        <section class="page-info">
            <div class="auto-container clearfix">
            	<div class="pull-left"><h2>Komandamız</h2></div>
                <div class="pull-right">
                	<ul class="bread-crumb clearfix">
                    	<li><a href="/">Əsas səhifə</a></li>
                        <li>Komandamız</li>
                    </ul>
                </div>
            </div>
        </section>
   <Leaders/>
   <Members/>
   </>
  )
}

export default Team