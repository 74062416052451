import React from 'react'
import { useParams } from 'react-router-dom';

const EventDetails = () => {

    const {id} = useParams();
  return (
    <>
        <div>Tədbir - {id}</div>
    </>
  )
}

export default EventDetails