import React, { useState } from 'react'


const files = [
  { name: 'Dosya 1', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Dosya 2', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Dosya 3', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Dosya 4', url: 'assets/downloads/PDFInfo.pdf' },
  { name: 'Dosya 5', url: 'assets/downloads/PDFInfo.pdf' },
];

const Documents = () => {

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (

     <>
     <section class="page-title" style={{backgroundImage:'url(assets/images/background/pagetitle-bg.jpg)'}}>
             <div class="auto-container">
               <h1>Sənədlər</h1>
             </div>
         </section>
         <section class="page-info">
             <div class="auto-container clearfix">
               <div class="pull-left"><h2>Sənədlər</h2></div>
                 <div class="pull-right">
                   <ul class="bread-crumb clearfix">
                       <li><a href="/">Əsas səhifə</a></li>
                         <li>Sənədlər</li>
                     </ul>
                 </div>
             </div>
         </section>
         <section class="team-section">
     <div class="auto-container">
     
         {/* <!--Styled Heading--> */}
         <div class="styled-heading centered">
             <h2>Sənədlər</h2>
         </div>
         
         <div class="row clearfix">
             
         <div className="p-4 max-w-md mx-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Axtar..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded-md"
        />
      </div>
      <div className="file-list space-y-4">
        {filteredFiles.map((file, index) => (
          <div key={index} className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
            <p className="text-lg font-semibold">{file.name}</p>
            <a href={file.url} download>
              <button className="download-button bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors">
                Yüklə
              </button>
            </a>
          </div>
        ))}
        {filteredFiles.length === 0 && (
          <p className="text-center text-gray-500">Fayl yoxdur</p>
        )}
      </div>
    </div>
             
             
             
         </div>
         
     </div>
 </section>
  
         </>
  )
}

export default Documents