import axios from "axios";
import React, { useEffect, useState } from "react";
import "./pending.css";
import { Link, useNavigate } from "react-router-dom";

export default function Pending() {
  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  const user = localStorage.getItem("user");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.hemkarbamid.az/hemkarbamid/dashboard.php/", {
        withCredentials: false,
      })
      .then(function (response) {
        const filteredProducts = response.data.filter(
          (product) => product.confirm == 0
        );
        setProducts(filteredProducts);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.hemkarbamid.az/hemkarbamid/dashboard.php/${id}/delete`, {
        withCredentials: false,
      })
      .then(function (response) {
        getProducts(); // Ürünleri tekrar alarak listeyi güncelle
      });
  };

  const acceptUser = (id) => {
    axios
      .put(`https://www.hemkarbamid.az/hemkarbamid/dashboard.php/${id}/update`, {
        id: id,        // ID'yi açıkça gönderiyoruz
        confirm: 1     // confirm değerini 1 olarak gönderiyoruz
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: false
      })
      .then(function (response) {
        console.log("User updated:", response.data); // Yanıtı konsolda göster
        getProducts(); // Listeyi güncelle
      })
      .catch(function (error) {
        console.error("Error updating user:", error); // Hataları konsolda göster
      });
  };
  
  
  
  
  


  

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <h3>{user}</h3>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary"
            type="submit"
            onClick={logoutSubmit}
          >
            Çıxış
          </button>
        </div>
        {/* <li>
          <Link style={{ color: "black" }} to="/dashboard/create">
            Üzv əlavə et
          </Link>
        </li> */}
        <li>
          <Link style={{ color: "black" }} to="/dashboard">
            Üzvlər
          </Link>
        </li>
        <div>
          <h1>Təsdiq gözləyənlər</h1>
          <table>
            <thead>
              <tr>
                <th>Sil</th>
                <th>Qəbul</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Soyad</th>
                <th>Ata adı</th>
                <th>Sehiddirmi?</th>
                <th>Şifrə</th>
                <th>Hid number</th>
                <th>FİN Kod</th>
                <th>Ad gunu</th>
                <th>Sobe</th>
                <th>Vezife</th>
                <th>Ise qebul</th>
                <th>Daxili nomre</th>
                <th>Mobil nomre</th>
                <th>Email</th>
                <th>Tesdiq</th>
                <th>Sekil</th>
                <th>Sehidlik senedi</th>
                <th>Qeydiyyat tarixi</th>
                
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>
                    <button onClick={() => acceptUser(user.id)}>Qəbul</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{user.father}</td>
                  <th>{user.isMartyr}</th>
                  <td>{user.password}</td>
                  <td>{user.hidNumber}</td>
                  <td>{user.finKod}</td>
                  <td>{user.birthday}</td>
                  <td>{user.department}</td>
                  <td>{user.position}</td>
                  <td>{user.acceptanceToWork}</td> 
                  <td>{user.innerNumber}</td>
                  <td>{user.mobileNumber}</td>
                  <td>{user.email}</td>
                  <td>{user.confirm}</td>
                  <td>Şəkil url</td>
                  <td>Şəhidlik senedi</td>
                  <td>{user.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

