import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [father, setFather] = useState("");
  const [hidNumber, setHidNumber] = useState("");
  const [finKod, setFinKod] = useState("");
  const [email, setEmail] = useState("");
  const [innerNumber, setİnnerNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [birthday, setBirthday] = useState("");
  const [acceptanceToWork, setAcceptanceToWork] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState(null);
  const [martyrDocument, setMartyrDocument] = useState(null);
  const [isMartyr, setIsMartyr] = useState("0");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlemartyrDocumentChange = (e) => {
    setMartyrDocument(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      toast.error("Şifrələr bərabər deyil");
      return;
    }
    if (isMartyr === '1' && martyrDocument === null) {
      toast.error("Şəhid və ya qaziliyi təsdiq edən sənəd daxil edilməyib");
      return;
    }
  
    const formData = new FormData();
    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("father", father);
    formData.append("hidNumber", hidNumber);
    formData.append("finKod", finKod);
    formData.append("email", email);
    formData.append("innerNumber", innerNumber);
    formData.append("mobileNumber", mobileNumber);
    formData.append("birthday", birthday);
    formData.append("acceptanceToWork", acceptanceToWork);
    formData.append("department", department);
    formData.append("position", position);
    formData.append("isMartyr", isMartyr);
    formData.append("password", password);
    if (image) {
      formData.append("image", image);
    }
    if (martyrDocument) {
      formData.append("martyrDocument", martyrDocument);
    }
  
    axios
      .post("https://www.hemkarbamid.az/hemkarbamid/dashboard.php/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success(
          "Admin tərəfindən təsdiq edildikdən sonra daxil etdiyiniz iş vəsiqə nömrəsi və şifrə ilə hesabınıza daxil ola bilərsiniz."
        );
        navigate("/login");
      })
      .catch((error) => {
        toast.error("Bir xəta baş verdi.");
      });
  };
  

  return (
    <>
      <section
        className="page-title"
        style={{
          backgroundImage: "url(assets/images/background/pagetitle-bg.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>QEYDİYYAT</h1>
        </div>
      </section>
      <section className="page-info">
        <div className="auto-container clearfix">
          <div className="pull-left">
            <h2>Qeydiyyat</h2>
          </div>
          <div className="pull-right">
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Əsas səhifə</a>
              </li>
              <li>Qeydiyyat</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="team-section">
        <div className="auto-container">
          <div className="styled-heading centered">
            <h2>Qeydiyyat</h2>
          </div>

          <div className="row clearfix">
            <div className="p-6 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Ad <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Ad"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Soyad <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Soyad"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Ata adı</label>
                  <input
                    type="text"
                    value={father}
                    onChange={(e) => setFather(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Ata adı"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Doğum tarixi <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Doğum tarixi"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                  Şəkil <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    className="w-full p-2 border rounded-md"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Email</label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    İşə qəbul tarixi <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    value={acceptanceToWork}
                    onChange={(e) => setAcceptanceToWork(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="İşə qəbul tarixi"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    İş vəsiqə nömrəsi <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    value={hidNumber}
                    onChange={(e) => setHidNumber(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="İş vəsiqə nömrəsi"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                  Şəxsiyyət vəsiqəsi FİN Kod <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    value={finKod}
                    onChange={(e) => setFinKod(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="FinKod"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Şöbə/Sahə</label>
                  <input
                    type="text"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Şöbə/Sahə"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Vəzifə</label>
                  <input
                    type="text"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Vəzifə"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Daxili nömrə</label>
                  <input
                    type="number"
                    value={innerNumber}
                    onChange={(e) => setİnnerNumber(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Daxili nömrə"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Mobil nömrə <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Mobil nömrə"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">
                    Qazi və ya şəhid ailəsindənsiniz?
                  </label>

                  <div className="flex items-center mb-2">
                    <input
                      type="radio"
                      id="qaziYes"
                      name="qaziAilesi"
                      value="1"
                      checked={isMartyr === "1"}
                      onChange={(e) => setIsMartyr(e.target.value)}
                      className="mr-2"
                      required
                    />
                    <label htmlFor="qaziYes" className="text-gray-700">
                      Bəli
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="qaziNo"
                      name="qaziAilesi"
                      value="0"
                      checked={isMartyr === "0"}
                      onChange={(e) => setIsMartyr(e.target.value)}
                      className="mr-2"
                      required
                    />
                    <label htmlFor="qaziNo" className="text-gray-700">
                      Xeyr
                    </label>
                  </div>
                </div>
                {isMartyr === "1" && (
                  <div className="mb-4">
                    <label className="block text-gray-700">
                      Təsdiq edən sənəd <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      onChange={handlemartyrDocumentChange}
                      className="w-full p-2 border rounded-md"
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Şifrə <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Şifrə"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Şifrə təsdiqi <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    placeholder="Şifrə təsdiqi"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full bg-[#223555] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    Göndər
                  </button>
                  <p>
                    Hesabınız var? <a href="/login">Giriş</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
