import React from 'react'
import { useParams } from 'react-router-dom';

const NewsDetails = () => {

  const {id} = useParams();
  return (
    <>
    <div>Xəbər - {id}</div>
</>
  )
}

export default NewsDetails