import React from 'react'

const About = () => {
  return (
    <>
    <section class="page-title" style={{backgroundImage:'url(assets/images/background/pagetitle-bg.jpg)'}}>
            <div class="auto-container">
            	<h1>Haqqımızda</h1>
            </div>
        </section>
        <section class="page-info">
            <div class="auto-container clearfix">
            	<div class="pull-left"><h2>Haqqımızda</h2></div>
                <div class="pull-right">
                	<ul class="bread-crumb clearfix">
                    	<li><a href="/">Əsas səhifə</a></li>
                        <li>Haqqımızda</li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="team-section">
    <div class="auto-container">
    
        {/* <!--Styled Heading--> */}
        <div class="styled-heading centered">
            <h2>Haqqımızda</h2>
        </div>
        
        <div class="row clearfix">
            
        <div class="text margin-bott-40"><p>SOCAR-ın Karbamid zavodunun istehsala başlaması azot gübrəsi idxalına ehtiyacı aradan qaldıracaq, bununla da ölkənin xarici valyuta xərcləri azalacaqdır. Zavod il ərzində 650-660 min ton istehsal gücünə malikdir. Yüksək azot tutumuna malik karbamid gübrəsinin istehsalı Azərbaycanın kənd təsərrüfatının inkişafına mühüm töhfə verəcək. İstehsal həcminin 70 faizi ixrac üçün nəzərdə tutulur ki, bu da ölkəyə hər il 160 milyon dollara qədər əlavə gəlirin daxil olmasına imkan verəcək.

Yanvarın 16-da Sumqayıtda Azərbaycan Respublikasının Prezidenti İlham Əliyev cənablarının iştirakı ilə SOCAR-ın Karbamid Zavodunun təntənəli açılış mərasimi keçirilmişdir. Mərasimdə SOCAR rəhbərliyi, zavodun tikintisində iştirak edən şirkətlərin təmsilçiləri iştirak etmişlər. SOCAR-ın yaydığı məlumata görə, ölkə başçısına bildirilmişdir ki, SOCAR son illərdə əməliyyatların dayanıqlığını təmin etmək və bir əmtəə növündən asılılığını azaltmaq məqsədi ilə neft-qaz və qaz-kimya sektorunu genişləndirmək xətti götürüb. Karbamid zavodu planlı və mərhələli şəkildə ərsəyə gətirilən böyük dəyər zəncirinin bir hissəsidir. Zavod il ərzində 435 milyon m3 təbii qaz həcmindən xammal kimi istifadə etməklə, 650-660 min ton karbamid məhsulu istehsal etmək gücündədir.

Karbamid gübrəsi ən yüksək azot tutumuna malikdir və buna görə də ən keyfiyyətli azot gübrəsi hesab olunur. Suda tez həll olma qabiliyyətinə görə seçilən bu gübrə növü ammonyak və karbon dioksidin sintezi nəticəsində əldə edilir. Karbamidə tələbat onun aşağı maya dəyəri, qənaətcil daşınması, rahat tətbiqi və kənd təsərrüfatında yüksək məhsuldarlığı ilə əlaqədardır. Bu məhsul digər kimyəvi maddələrin istehsalında və əczaçılıqda da geniş istifadə olunur.

İndiyədək azot gübrələri Azərbaycana idxal edildiyi halda yeni zavod həm daxili bazarın tələbatını tam ödəyəcək, həm də istehsal etdiyi məhsulun təxminən 70 faizini ixrac edəcəkdir. Nəticədə, daha əvvəl idxal üçün sərf edilən illik 90 milyon dollara qədər valyuta vəsaitləri ölkədə qalacaq, eyni zamanda zavod ölkəmiz üçün əlavə gəlir mənbəyinə çevriləcək.

Ölkə başçısına məruzə edən SOCAR-ın prezidenti Rövnəq Abdullayev demişdir: “Tikintinin pik vaxtında zavodun inşasına ümumilikdə 3600 nəfərdən artıq işçi cəlb olunmuşdur. Hazırda zavodda təxminən 500 nəfər daimi işlə təmin edilib. Bunun üçün SOCAR öz təhsil və təqaüd proqramlarının vasitəsi ilə zəruri səriştəliklərə malik olan mütəxəssisləri öncədən hazırlamışdır. Zavodun inşaat işlərində təhlükəsizlik tələblərinə maksimum dərəcədə riayət edilmiş, 18 milyon adam-saatdan çox iş həcmi qəzasız şəraitdə yerinə yetirilmişdir.”

SOCAR-ın Karbamid zavodu hazırda ölkədə qeyri-neft sektorunda həyata keçirilmiş ən böyük layihədir və ümumi dəyəri təxminən 800 milyon avro təşkil edir. Layihənin maliyyələşdirilməsi ilkin dövrdə dövlət büdcəsindən ayrılan vəsaitlər hesabına həyata keçirilmişdir. 27 yanvar 2017-ci il tarixində Cənubi Koreyanın İxrac-idxal Bankından (KEXIM), habelə Societe Generale (Fransa), Deutsche Bank (Almaniya) və UniCredit (Avstriya) banklarından dövlət zəmanəti əsasında 500 milyon avro məbləğində vəsait cəlb edilmişdir. Tərəfdaş bankların dəstəyi ilə cəlb edilən uzunmüddətli kapital dövlət büdcəsinin yükünü azaltmışdır. Həmçinin layihə ilə bağlı vergi və rüsumların, habelə digər xərclərin ödənilməsi məqsədi ilə SOCAR tərəfindən təxminən 100 milyon avro məbləğində vəsait ayrılmışdır.

Xatırladaq ki, Karbamid zavodunun təməli 2011-ci ildə Azərbaycan Respublikasının Prezidenti cənab İlham Əliyevin iştirakı ilə qoyulmuşdur. Zavodun tikintisi Cənubi Koreyanın “Samsung Engineering Co., Ltd.” şirkəti tərəfindən, ammonyak üzrə Danimarkanın “Haldor Topsoe” və karbamid istehsalı üzrə Niderlandın “Stamicarbon B.V” şirkətlərinin texnologiyası əsasında həyata keçirilmişdir. Finlandiyanın “Neste Engineering Solutions Oy ” şirkəti layihənin idarə edilməsi üzrə məsləhət və müstəqil təftiş xidmətləri göstərib.</p></div>
            
            
            
        </div>
        
    </div>
</section>
 
        </>
  )
}

export default About