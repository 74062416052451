import React, { useState } from 'react';
import { FaSearch, FaUser, FaQuestionCircle, FaShoppingCart, FaTh, FaChevronRight } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../redux/authSlice';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';



const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const memberName = useSelector((state) => state.auth.member);
  const dispatch = useDispatch();
  console.log(isLoggedIn)

  const handleLogout = () => {
    Swal.fire({
      title: 'Əminsiz?',
      text: "Hesabınızdan çıxmaq istəyirsiz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bəli',
      cancelButtonText: 'Xeyr'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        toast.error("Hesabınızdan çıxıldı")
      }
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language) => {
    console.log(`Selected language: ${language}`);
    setIsOpen(false); // Dropdown'u kapat
  };
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchClick = () => {
    setIsSearching(!isSearching);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log(`Searching for: ${searchQuery}`);
    // Arama işlemi burada yapılabilir.
  };

  return ( 
    <>
      <header class="main-header">
            {/* <!-- Top Bar --> */}
            <div class="top-bar">
                <div class="auto-container clearfix">
                    {/* <!-- Top Left --> */}
                    {/* <div class="top-left"> */}
                    {/* <div class="social-links">
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}} class="fa fa-facebook-f"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-twitter"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-google-plus"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-linkedin"></span></a>
                                            <a href="#"><span style={{fontSize:'16px', marginRight:'10px'}}  class="fa fa-skype"></span></a>
                                        </div> */}
                    {/* </div> */}
                    
                    {/* <!-- Top Right --> */}
                    
                </div>
            </div>
            {/* <!-- Top Bar End --> */}
            
            {/* <!--Middle Bar--> */}
            <div class="middle-bar">
            	<div class="auto-container">
                	<div class="clearfix">
                    <div class="logo-column">
                    <div className="flex space-x-2 p-2 w-1/6 mx-auto rounded-md">
                    <div className="relative inline-block">
      <button
        className="flex items-center justify-center w-16 h-16 bg-white rounded-md border-gray-100 border-2"
        onClick={toggleDropdown}
      >
        AZ <span className="ml-1">&#9662;</span>
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-lg">
          <ul className="py-1">
            <li
              className="cursor-pointer px-4 py-2 hover:bg-gray-100"
              onClick={() => selectLanguage("AZ")}
            >
              AZ
            </li>
            <li
              className="cursor-pointer px-4 py-2 hover:bg-gray-100"
              onClick={() => selectLanguage("EN")}
            >
              EN
            </li>
          </ul>
        </div>
      )}
    </div>
    <div className="relative inline-block">
      <button
        className="flex items-center justify-center w-16 h-16 bg-white rounded-md border-gray-100 border-2"
        onClick={handleSearchClick}
      >
        <span role="img" aria-label="search">
          <span className="fa fa-search"></span>
        </span>
      </button>

      {isSearching && (
        <form onSubmit={handleSearchSubmit} className="absolute mt-2">
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Search..."
            className="border border-gray-300 rounded-md px-4 py-2 w-64"
          />
        </form>
      )}
    </div>
    </div>
                        </div>
                        {/* <!--Info Column--> */}
                        <div class="info-column pull-left">
                        <figure class="logo"><a href="/"><img src="/assets/images/logo.jpg" alt="" title="SOCAR HIK"></img></a></figure>
                        </div>
                        
                        {/* <!--Info Column--> */}
                        <div class="info-column pull-right">
                        <img src="/assets/images/socar2.jpg" className='h-[100px] w-[200px]' alt="" title="SOCAR HIK"></img>
                            {/* <p>Ünvan: Bakı, Azərbaycan</p>
                            <p>Əlaqə: +994 50 383 22 22</p> */}
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <!-- Lower Section --> */}
            <div class="lower-section">
                <div class="auto-container">
                    
                    {/* <!--Outer Box--> */}
                    <div class="outer-box clearfix">
                         
                        {/* <!-- Hidden Nav Toggler --> */}
                        <div class="nav-toggler">
                        <button class="hidden-bar-opener"><span class="icon fa fa-bars"></span></button>
                        </div>
                        {/* <!-- / Hidden Nav Toggler --> */}
                         
                        
                        {/* <!-- Main Menu --> */}
                        <nav class="main-menu">
                            
                            <div class="navbar-header">
                                {/* <!-- Toggle Button -->    	 */}
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                            </div>
                            
                            <div class="navbar-collapse collapse clearfix">
                                <ul class="navigation">
                                    <li class="current"><a href="/">Əsas səhifə</a></li>
                                    <li class="dropdown"><a href="#">Haqqımızda</a>
                                    	<ul>
                                            <li><a href="/about">Məlumat</a></li>
                                            <li class="dropdown"><a href="/team">Komandamız</a>
                                            </li>
                                            <li><a href="/partners">Tərəfdaşlarımız</a></li>
                                            <li><a href="/faq">FAQ</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="#">Xidmətlər</a>
                                    	<ul>
                                            <li><a href="/events">Tədbirlər</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="#">İşlərimiz</a>
                                    	<ul>
                                            <li><a href="/news">Xəbərlər</a></li>
                                            <li><a href="/statistics">Statistika</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="#">Sənədlər</a>
                                    	<ul>
                                            <li><a href="/laws">Qanunvericilik</a></li>
                                            <li><a href="/documents">Sənəd nümunələri</a></li>
                                        </ul>
                                    </li>
                                    <li class="dropdown"><a href="/offers">Rəy və Təklif</a></li>
                                </ul>
                            </div>
                        </nav>
                        {/* <!-- Main Menu End--> */}
                        
                        <nav>
                        <ul>
      <li>
        {isLoggedIn ? (
          <div onClick={handleLogout} className="link-box">
            <p className='theme-btn cursor-pointer'>{memberName}</p>
          </div>
        ) : (
          <div className="link-box">
            <a href="/register" className="theme-btn">ÜZVLÜK</a>
          </div>
        )}
      </li>
    </ul>

    </nav>
                        
                    </div>
                </div>
            </div>
            {/* <!-- Lower Section End--> */}
            
        </header>
    </>
  );
};

export default Header;




























