import React, { useState } from 'react';

const Faq = () => {
  // State for active tab and active accordion
  const [activeTab, setActiveTab] = useState('tab-one');
  const [activeAccordions, setActiveAccordions] = useState({});

  // Function to handle tab switching
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Function to handle accordion toggle
  const handleAccordionToggle = (index) => {
    setActiveAccordions((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle accordion state
    }));
  };

  return (
    <>
      <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/pagetitle-bg.jpg)' }}>
        <div className="auto-container">
          <h1>Tez-tez VERİLƏN suallar</h1>
        </div>
      </section>

      <section className="page-info">
        <div className="auto-container clearfix">
          <div className="pull-left">
            <h2>FAQ</h2>
          </div>
          <div className="pull-right">
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Əsas səhifə</a>
              </li>
              <li>FAQ</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="team-section">
        <div className="auto-container">
          <div className="styled-heading centered">
            <h2>Tez-tez verilən suallar</h2>
          </div>

          <section className="tabs-section">
            <div className="auto-container">
              <div className="tabs-box style-one">
                <ul className="tab-buttons text-center clearfix">
                  <li
                    className={`tab-btn ${activeTab === 'tab-one' ? 'active-btn' : ''}`}
                    onClick={() => handleTabClick('tab-one')}
                  >
                    Law &amp; Justice
                  </li>
                  <li
                    className={`tab-btn ${activeTab === 'tab-two' ? 'active-btn' : ''}`}
                    onClick={() => handleTabClick('tab-two')}
                  >
                    Criminal Law
                  </li>
                  <li
                    className={`tab-btn ${activeTab === 'tab-three' ? 'active-btn' : ''}`}
                    onClick={() => handleTabClick('tab-three')}
                  >
                    Family Law
                  </li>
                </ul>

                <div className="tabs-content">
                  {/* Tab 1 content */}
                  <div className={`tab ${activeTab === 'tab-one' ? 'active-tab' : ''}`} id="tab-one">
                    <div className="accordion-box style-three">
                      <div className="accordion accordion-block">
                        <div className="accord-btn" onClick={() => handleAccordionToggle(1)}>
                          <div className="icon-box">
                            <span className="icon fa fa-gear"></span>
                          </div>
                          <h4>What is the International Court of Justice?</h4>
                        </div>
                        <div className="accord-content" style={{ display: activeAccordions[1] ? 'block' : 'none' }}>
                          <div className="clearfix">
                            <p>
                              Only States are eligible to appear before the Court in contentious cases. At present, this
                              is basically means the 192 United Nations Member States.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion accordion-block">
                        <div className="accord-btn" onClick={() => handleAccordionToggle(2)}>
                          <div className="icon-box">
                            <span className="icon fa fa-gear"></span>
                          </div>
                          <h4>Who may submit cases to the Court?</h4>
                        </div>
                        <div className="accord-content" style={{ display: activeAccordions[2] ? 'block' : 'none' }}>
                          <div className="clearfix">
                            <p>
                              Only States are eligible to appear before the Court in contentious cases. At present, this
                              is basically means the 192 United Nations Member States.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Tab 2 content */}
                  <div className={`tab ${activeTab === 'tab-two' ? 'active-tab' : ''}`} id="tab-two">
                    <div className="accordion-box style-three">
                      <div className="accordion accordion-block">
                        <div className="accord-btn" onClick={() => handleAccordionToggle(3)}>
                          <div className="icon-box">
                            <span className="icon fa fa-gear"></span>
                          </div>
                          <h4>What is the International Court of Justice?</h4>
                        </div>
                        <div className="accord-content" style={{ display: activeAccordions[3] ? 'block' : 'none' }}>
                          <div className="clearfix">
                            <p>
                              Only States are eligible to appear before the Court in contentious cases. At present, this
                              is basically means the 192 United Nations Member States.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion accordion-block">
                        <div className="accord-btn" onClick={() => handleAccordionToggle(4)}>
                          <div className="icon-box">
                            <span className="icon fa fa-gear"></span>
                          </div>
                          <h4>Who may submit cases to the Court?</h4>
                        </div>
                        <div className="accord-content" style={{ display: activeAccordions[4] ? 'block' : 'none' }}>
                          <div className="clearfix">
                            <p>
                              Only States are eligible to appear before the Court in contentious cases. At present, this
                              is basically means the 192 United Nations Member States.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Tab 3 content */}
                  <div className={`tab ${activeTab === 'tab-three' ? 'active-tab' : ''}`} id="tab-three">
                    <div className="accordion-box style-three">
                      <div className="accordion accordion-block">
                        <div className="accord-btn" onClick={() => handleAccordionToggle(5)}>
                          <div className="icon-box">
                            <span className="icon fa fa-gear"></span>
                          </div>
                          <h4>What is the International Court of Justice?</h4>
                        </div>
                        <div className="accord-content" style={{ display: activeAccordions[5] ? 'block' : 'none' }}>
                          <div className="clearfix">
                            <p>
                              Only States are eligible to appear before the Court in contentious cases. At present, this
                              is basically means the 192 United Nations Member States.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion accordion-block">
                        <div className="accord-btn" onClick={() => handleAccordionToggle(6)}>
                          <div className="icon-box">
                            <span className="icon fa fa-gear"></span>
                          </div>
                          <h4>Who may submit cases to the Court?</h4>
                        </div>
                        <div className="accord-content" style={{ display: activeAccordions[6] ? 'block' : 'none' }}>
                          <div className="clearfix">
                            <p>
                              Only States are eligible to appear before the Court in contentious cases. At present, this
                              is basically means the 192 United Nations Member States.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Faq;
