import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const priceShort = [ 
  {
    image: "assets/images/partners2.png",
    path: 'https://shamakhipalace.az/az'
  },
  {
    image: "assets/images/partners3.png",
    path: 'https://voguehotel.az/'
  }, 
  {
    image: "assets/images/partners4.png",
    path: 'https://planetofhotels.com/en/azerbaijan/qusar/ilk-inn-hotel'
  },
  {
    image: "assets/images/partners5.png",
    path: 'https://www.riverinn.az/en'
  },
  {
    image: "assets/images/partners6.jpg",
    path: 'https://galaalti.az/az'
  },
  {
    image: "assets/images/partners7.webp",
    path: 'https://elresort.az/az/'
  },
  {
    image: "assets/images/partners8.webp",
    path: 'https://yurdhotel.az/az/'
  },
  {
    image: "assets/images/partners9.png",
    path: 'https://www.marxalresort.az/'
  },
  {
    image: "assets/images/partners10.png",
    path: 'https://qubapalace.com/'
  },
  {
    image: "assets/images/partners11.png",
    path: 'https://elfaro.az/'
  },
  {
    image: "assets/images/partners12.jpg",
    path: 'https://www.bravosupermarket.az/'
  },
  {
    image: "assets/images/partners13.png",
    path: 'https://qayali.az/'
  },
];

// Custom arrow components
const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className} slick-prev`} onClick={onClick}>
      &#8592; {/* Left arrow icon */}
    </button>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className} slick-next`} onClick={onClick}>
      &#8594; {/* Right arrow icon */}
    </button>
  );
};

const Partners = () => {
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(#F7F8FB, rgba(0, 0, 0, 0.1)), url('./map.png')`,
        height: "70vh",
        maxHeight: "50vh",
      }}
      className="container-xxl py-5 h-screen flex flex-col justify-center"
    >
      <div className="w-[90%] mx-auto">
        <div className="container">
          <div className="styled-heading centered mb-[50px]">
            <h2>Tərəfdaşlarımız</h2>
          </div>
          <div data-aos="zoom-in">
            <Slider {...settings}>
              {priceShort.map((data, index) => (
               <a href={data.path}>
                 <div className="flex justify-center items-center" key={index}>
                  <div className="h-40 w-40 mx-4">
                    <img
                      src={data.image}
                      alt={data.plan}
                      className="testimonial-img h-40 w-40 object-contain"
                    />
                  </div>
                </div>
               </a>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;

