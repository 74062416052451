import React from 'react';
import Workers from '../../Components/Workers/Workers';

export const price = [
  {
    image: "assets/images/partners2.png",
  },
  {
    image: "assets/images/partners3.png",
  },
  {
    image: "assets/images/partners4.png",
  },
  {
    image: "assets/images/partners5.png",
  },
  {
    image: "assets/images/partners6.jpg",
  },
  {
    image: "assets/images/partners7.webp",
  },
  {
    image: "assets/images/partners8.webp",
  },
  {
    image: "assets/images/partners9.png",
  },
  {
    image: "assets/images/partners10.png",
  },
  {
    image: "assets/images/partners11.png",
  },
  {
    image: "assets/images/partners12.jpg",
  },
  {
    image: "assets/images/partners13.png",
  }
];

const Partners = () => {
  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: 'url(assets/images/background/pagetitle-bg.jpg)' }}
      >
        <div className="auto-container">
          <h1>Tərəfdaşlarımız</h1>
        </div>
      </section>
      <section className="page-info">
        <div className="auto-container clearfix">
          <div className="pull-left">
            <h2>Tərəfdaşlarımız</h2>
          </div>
          <div className="pull-right">
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Əsas səhifə</a>
              </li>
              <li>Tərəfdaşlarımız</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="team-section">
      <div class="styled-heading centered">
            <h2>Tərəfdaşlarımız</h2>
        </div>
        <div className="auto-container">
          <div className="corporation content grid mtop">
            {price.map((item, index) => (
              <div className="flex justify-center items-center" key={index}>
                <img  src={item.image} alt={`item-${index}`} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CSS styles to improve layout */}
      <style jsx>{`
        .corporation.content.grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          gap: 20px;
          justify-items: center;
        }

        .box {
          width: 100%;
          max-width: 200px;
          padding: 10px;
          background-color: #fff;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .box img {
          width: 100%;
          height: auto;
          display: block;
          border-radius: 8px;
        }

        @media (max-width: 768px) {
          .corporation.content.grid {
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          }

          .box {
            max-width: 150px;
          }
        }
      `}</style>
    </>
  );
};

export default Partners;
