import React from 'react'

const Newsletter = () => {
  return (
    <section class="info-section">
            <div class="auto-container">
            	<div class="row clearfix">
                	
                    {/* <!--Info Column--> */}
                    <article class="info-column col-md-4 col-sm-4 col-xs-12">
                    	<div class="inner-box">
                        	<div class="icon"><span class="fa fa-map-marker"></span></div>
                            <h3>Ünvanımız</h3>
                            <div class="text"><p>Sumqayıt şəhəri <br></br>Quba yolu, 35-ci km</p></div>
                        </div>
                    </article>
                     
                    {/* <!--Info Column--> */}
                    <article class="info-column col-md-4 col-sm-4 col-xs-12">
                    	<div class="inner-box">
                        	<div class="icon"><span class="fa fa-phone"></span></div>
                            <h3>Bizimlə Əlaqə</h3>
                            <div class="text"><p>+994 50 323 22 22, +994 55 282 48 23 <br></br>hemkarbamid@info.az</p></div>
                        </div>
                    </article>
                    
                    {/* <!--Info Column--> */}
                    <article class="info-column col-md-4 col-sm-4 col-xs-12">
                    	<div class="inner-box">
                        	<div class="icon"><span class="fa fa-clock-o"></span></div>
                            <h3>İş saatlar</h3>
                            <div class="text"><p>Bazar ertəsi-Cümə: 08.00 - 17.00 <br></br>Şənbə-Bazar istirahət</p></div>
                        </div>
                    </article>
                    
                    
                </div>
            </div>
        </section>
  )
}

export default Newsletter